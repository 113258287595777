.button.simple {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: auto;
    padding: 0 5rem;
    background-color: #349FFF;
    border: 2px solid #349FFF;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    transition: all 0.35s ease-out;
}
.button.simple:hover {
    background-color: #fff;
    color: #000;
}
.button.simple:active {
    border-color: #000;
}
.button.simple.disabled {
    color: #8b8b8b;
    border-color: #cdcdcd;
    background-color: #cdcdcd;
    pointer-events: none;
}
@media (max-width: 1023px) {
    .button.simple {
        font-size: 18px;
    }
}
@media (max-width: 767px) {
    .button.simple {
        font-size: 16px;
    }
}


.input-item {
    position: relative;
    margin-bottom: 50px;
}
.input-item-control {
    z-index: 10;
    box-sizing: border-box;
    border: 1px solid #cdcdcd;
    outline: none;
    width: 100%;
    font-size: 18px;
    padding: 20px 10px;
    height: 60px;
}
.input-item-control:focus {
    border-color: #000;
}
.input-item-control:focus ~ .input-item-label {
    pointer-events: none;
    color: #8b8b8b;
    transform: translate(-10px, -30px) scale(0.8);
    background-color: #fff;
}
.input-item-label {
    pointer-events: none;
    position: absolute;
    padding: 0 5px;
    line-height: 20px;
    left: 10px;
    top: 22px;
    transition: all 0.35s ease-out;
}
.input-item-label.raised {
    pointer-events: none;
    color: #8b8b8b;
    transform: translate(-10px, -30px) scale(0.8);
    background-color: #fff;
}
.input-item-error {
    position: absolute;
    box-sizing: border-box;
    color: #fff;
    background-color: #FF0D3C;
    width: 100%;
    height: 30px;
    padding: 0 15px;
    font-weight: 600;
    font-size: 12px;
    font-style: italic;
    line-height: 30px;
}
.input-item-error:empty {
    display: none;
}
.input-item-hint {
    color: #8b8b8b;
    font-size: 12px;
    padding: 0 10px;
    margin-top: 5px;
}
.input-item-hint a {
    transition: all 0.35s ease-out;
}
.input-item-hint a:hover {
    color: #e21114;
}
.input-item.disabled .input-item-control {
    color: #545454;
    background-color: #f3f3f3;
    pointer-events: none;
}
.input-item.error .input-item-label {
    color: #ff7272;
}
.input-item.error .input-item-control {
    border-color: #FF0D3C;
}
.input-item.error .input-item-hint {
    margin-top: 36px;
}
@media (max-width: 1023px) {
    .input-item-control, .input-item-label {
        font-size: 18px;
    }
}
@media (max-width: 767px) {
    .input-item-control, .input-item-label {
        font-size: 16px;
    }
}

